@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.73) ;
  font-weight: 400;
}
::-webkit-scrollbar{
  max-width: 5px;
}
::-webkit-scrollbar-thumb{
  background-color:grey;
  border-radius: 10px;
}

